nthLine = (el) ->
    content = jQuery(el).text()
    tag = jQuery(el).prop 'tagName'
    words = content.split(' ')
    console.log words
    cache = words[0]
    
    lines = []
    
    jQuery(el).after "<#{tag} id='sample'>#{words[0]}</#{tag}>"
    sample = jQuery(el).next '#sample'
    size = sample.height()
    newSize = size
    i = 1
    while i < words.length
        sampleText = sample.html()
        cache = cache + ' ' + words[i]
        marker = [ i ]
        sample.html sampleText + ' ' + words[i]
        newSize = sample.height()
        
        if size != newSize
            
            cache = cache.substring(0, cache.length - (words[i].length + 1))
            
            lines.push cache
            
            cache = words[i]
            
            size = sample.height()
        i++
    
    lines.push cache
    
    cache = jQuery('')
    
    i = 0
    while i < lines.length
        cache = cache.append('<span class="line line-' + [ i ] + '">' + lines[i] + '</span>')
        i++
    
    sample.remove()
    
    # cache = cache.substring(1)
    
    jQuery(el).addClass('has-lines').html cache

getSize = (check = null) ->
    size = jQuery("#sizers div:visible").attr 'id'
    if check?
        if jQuery.isArray(check)
            return check.indexOf(size) > -1
        else
            return size is check
    else
        return size

(($) ->

    $('iframe').filter -> 
        return this.src.match(/(youtube\.com|youtu\.be|vimeo\.com)/i);
    .wrap "<div class='video-wrap'></div>"

    headerHeight = $("header").outerHeight()

    $(window).on 'resize', ->
        $("#hero, #intro").removeAttr 'style'

        if getSize(['xl', 'ipad-land', 'lg', 'md']) and $("body").hasClass 'page-template-adventure'
            introHeight = $("#intro").outerHeight()
            heroHeight = parseInt $("#hero").css('height')
            padding = parseInt $("#hero").css('padding-top')
            newPadding = padding + (introHeight / 2) - headerHeight
            $("#hero").css
                height: heroHeight - newPadding
                paddingBottom: newPadding
            $("#intro").css
                marginTop: -(introHeight / 2)
                transform: 'none'

        # $("h1, h2, h3, h4, h5, h6").each ->
        #     nthLine $(@)
    .trigger 'resize'

    emailSignup = null
    emailSignup = setInterval ->
        if $(".ctct-form-element[name=email_address]").length
            $(".ctct-form-element[name=email_address]").attr 'placeholder', 'Enter your email address.'
            clearInterval emailSignup
    , 100

    $(window).scroll () ->
        scrollTop = $(@).scrollTop()

        if not $("body").hasClass('blog') and not $("body").hasClass('single-post')
            if scrollTop > ($("#hero").outerHeight() / 2) - headerHeight
                $("header").addClass 'scroll'
            else
                $("header").removeClass 'scroll'
    .trigger 'scroll'

    $(".header-hamburger, #mobile-menu .menu-close").click (e) ->
        $("#mobile-menu").toggleClass 'show'

    $(".how-button").click (e) ->
        e.preventDefault()
        $("#hero-video").addClass 'show'

    $("#hero-video .video-close").click () ->
        $("#hero-video").removeClass 'show'

    slideNext = (slider, slides, nav) ->
        current = slides.filter '.current'
        currentNav = nav.filter '.current'

        if slides.last().hasClass 'current'
            slider.css 'margin-left', 0
            slides.first().add(nav.first()).addClass 'current'
        else
            slider.css 'margin-left', '-=' + (current.next().index() * 100) + 'vw'
            current.next().add(currentNav.next()).addClass 'current'
        
        current.add(currentNav).removeClass 'current'

    goToSlide = (index, nav, slider, slides) ->
        current = slides.filter '.current'
        currentNav = nav.filter '.current'

        slider.css 'margin-left', '-=' + (index * 100) + 'vw'
        current.add(currentNav).removeClass 'current'
        $(slides[index]).add($(nav[index])).addClass 'current'

    $(".page-section--slider").each () ->
        int = null

        slider = $(".slider-wrap", @)
        slides = $(".slider-slide", slider)
        nav = $(".slider-nav .nav-item")

        slides.first().add(nav.first()).addClass 'current'

        nav.click (e) ->
            e.preventDefault()
            clearInterval int
            int = setInterval ->
                slideNext slider, slides, nav
            , 6000
            goToSlide $(@).index(), nav, slider, slides

        int = setInterval ->
            slideNext slider, slides, nav
        , 6000

    $(".faq-question").click () ->
        $(@).siblings('.faq-answer').animate { height: 'toggle' }, 300

) jQuery